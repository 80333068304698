import {Injectable} from '@angular/core';
import {finalize, tap, map} from 'rxjs/operators';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpXsrfTokenExtractor,
  HttpErrorResponse, HttpHeaderResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NzMessageService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpErrorInterceptor {
  constructor(private message: NzMessageService,
              private translateService: TranslateService,
              private tokenExtractor: HttpXsrfTokenExtractor,
              private router: Router) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = window.localStorage.getItem('token');
    var expires=new Date();
    expires.setTime(expires.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);
    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authToken)
               .set('Cache-Control', 'no-cache')
               .set('Pragma', 'no-cache')
               .set('Expires',expires.toUTCString()) 
    });
    return next.handle(authReq)
      .pipe(
        tap(
          event => {
            if (event instanceof HttpResponse) {
              const token = event.headers.get('x-token');
              if (token) {
                window.localStorage.setItem('token', token);
              }
            }
          },
          error => {
            if (error.status === 401) {
              this.router.navigate(['passport']);
              this.createErrorMessage('401');
            } else {
              this.createErrorMessage(error.error.Code);
            }
          }
        ),
        finalize(() => {
        })
      );
  }

  createErrorMessage(errorCode: string) {
    this.translateService.get('Error.' + errorCode).subscribe(translation => {
      this.createMessage('error', translation);
    });
  }

  createMessage(type: string, text: string): void {
    this.message.create(type, text);
  }
}
