import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap, delay} from 'rxjs/operators';
import {AppConfig} from './app.config';

@Injectable()
export class ApiService {


  baseApiUrl = AppConfig.settings.webApiBaseUrl + "api/";

//baseApiUrl = 'http://139.217.235.114:8070/api/';
  //baseApiUrl = 'http://localhost:57026/api/';
  //baseApiUrl = ' http://10.160.100.109:57023/api/'
  //baseApiUrl = ' http://localhost:57026/api/'
  authBaseUrl = this.baseApiUrl + 'Auth/';
  factoryBaseUrl = this.baseApiUrl + 'Factory/';
  roleBaseUrl = this.baseApiUrl + 'Role/';
  gatewayBaseUrl = this.baseApiUrl + 'Gateway/';
  furnaceBaseUrl = this.baseApiUrl + 'Furnace/';
  functionBaseUrl = this.baseApiUrl + 'Function/';
  userBaseUrl = this.baseApiUrl + 'User/';
  roleFunctionBaseUrl = this.baseApiUrl + 'RoleFunction/';

  loginApiUrl = this.authBaseUrl + 'Login';
  getFactoryListUrl = this.factoryBaseUrl + 'GetFactoryList';
  factoryPageQueryApiUrl = this.factoryBaseUrl + 'PageQuery';
  addFactoryApiUrl = this.factoryBaseUrl + 'AddFactory';
  updateFactoryApiUrl = this.factoryBaseUrl + 'UpdateFactory';
  removeFactoryApiUrl = this.factoryBaseUrl + 'RemoveFactory/';
  // Role
  rolePageQueryApiUrl = this.roleBaseUrl + 'PageQuery';
  getRolesApiUrl = this.roleBaseUrl + 'GetRoles';
  getUserRoleApiUrl = this.roleBaseUrl + 'GetUserRole/';
  addRoleApiUrl = this.roleBaseUrl + 'AddRole';
  updateRoleApiUrl = this.roleBaseUrl + 'UpdateRole';
  removeRoleApiUrl = this.roleBaseUrl + 'RemoveRole/';
  getRoleByIdApiUrl = this.roleBaseUrl + 'GetRole/';

  gatewayPageQueryApiUrl = this.gatewayBaseUrl + 'PageQuery';
  addGatewayApiUrl = this.gatewayBaseUrl + 'AddGateway';
  updateGatewayApiUrl = this.gatewayBaseUrl + 'UpdateGateway';
  removeGatewayApiUrl = this.gatewayBaseUrl + 'RemoveGateway/';
  // Frunace
  furnacePageQueryApiUrl = this.furnaceBaseUrl + 'PageQuery';
  // Function
  getFunctionDataApiUrl = this.functionBaseUrl + 'GetFunctionData';

  // User
  userPageQueryApiUrl = this.userBaseUrl + 'PageQuery';
  addUserApiUrl = this.userBaseUrl + 'AddUser';
  updateUserApiUrl = this.userBaseUrl + 'UpdateUser';
  removeUserApiUrl = this.userBaseUrl + 'RemoveUser/';
  getUserByIdApiUrl = this.userBaseUrl + 'GetUser/';
  changePasswordApiUrl = this.userBaseUrl + 'ChangePassword/';
  // RoleFunction
  getRoleFunctionApiUrl = this.roleFunctionBaseUrl + 'GetRoleFunction';

  constructor(private http: HttpClient) {
  }

  login(queryModel): Observable<any> {
    return this.http.post(this.loginApiUrl, queryModel);
  }

  factoryPageQuery(queryModel): Observable<any> {
    return this.http.get(this.factoryPageQueryApiUrl, {
      params: new HttpParams({
        fromObject: queryModel
      })
    });
  }

  getFactoryList(): Observable<any> {
    return this.http.get(this.getFactoryListUrl);
  }

  addFactory(addModel): Observable<any> {
    return this.http.post(this.addFactoryApiUrl, addModel);
  }

  updateFactory(updateModel): Observable<any> {
    return this.http.post(this.updateFactoryApiUrl, updateModel);
  }

  removeFactory(factoryId): Observable<any> {
    return this.http.delete(this.removeFactoryApiUrl + factoryId);
  }

  gatewayPageQuery(queryModel): Observable<any> {
    return this.http.get(this.gatewayPageQueryApiUrl, {
      params: new HttpParams({
        fromObject: queryModel
      })
    });
  }

  addGateway(addModel): Observable<any> {
    return this.http.post(this.addGatewayApiUrl, addModel);
  }

  updateGateway(updateModel): Observable<any> {
    return this.http.post(this.updateGatewayApiUrl, updateModel);
  }

  removeGateway(gatewayId): Observable<any> {
    return this.http.delete(this.removeGatewayApiUrl + gatewayId);
  }

  getRoleList(factoryid): Observable<any> {
    const model = {
      factoryid: factoryid
    };
    return this.http.get(this.getRolesApiUrl, {
      params: new HttpParams({
        fromObject: model
      })
    });
  }

  rolePageQuery(queryModel): Observable<any> {
    return this.http.get(this.rolePageQueryApiUrl, {
      params: new HttpParams({
        fromObject: queryModel
      })
    });
  }

  addRole(addModel): Observable<any> {
    return this.http.post(this.addRoleApiUrl, addModel);
  }

  updateRole(updateModel): Observable<any> {
    return this.http.post(this.updateRoleApiUrl, updateModel);
  }

  getRoleById(roleId): Observable<any> {
    return this.http.get(this.getRoleByIdApiUrl + roleId);
  }

  removeRole(roleId): Observable<any> {
    return this.http.delete(this.removeRoleApiUrl + roleId);
  }

  furnacePageQuery(queryModel): Observable<any> {
    return this.http.get(this.furnacePageQueryApiUrl, {
      params: new HttpParams({
        fromObject: queryModel
      })
    });
  }

  getFunctionData(): Observable<any> {
    return this.http.get(this.getFunctionDataApiUrl);
  }

  userPageQuery(queryModel): Observable<any> {
    return this.http.get(this.userPageQueryApiUrl, {
      params: new HttpParams({
        fromObject: queryModel
      })
    });
  }

  addUser(addModel): Observable<any> {
    return this.http.post(this.addUserApiUrl, addModel);
  }

  updateUser(updateModel): Observable<any> {
    return this.http.post(this.updateUserApiUrl, updateModel);
  }

  getUserById(userId): Observable<any> {
    return this.http.get(this.getUserByIdApiUrl + userId);
  }

  getUserRoleFactoryById(userId): Observable<any> {
    return this.http.get(this.getUserRoleApiUrl + userId);
  }

  changePassword(model) {
    return this.http.post(this.changePasswordApiUrl, model);
  }

  removeUser(gatewayId): Observable<any> {
    return this.http.delete(this.removeUserApiUrl + gatewayId);
  }

  getRoleFunction(): Observable<any> {
    return this.http.get(this.getRoleFunctionApiUrl);
  }
}
