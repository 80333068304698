import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {tap, delay} from 'rxjs/operators';
import {ApiService} from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(private apiService: ApiService) {

  }

  login(loginModel): Observable<any> {
    return this.apiService.login(loginModel).pipe(tap(
      val => this.isLoggedIn = true
    ));
    /*return of(true).pipe(
      delay(1000),
      tap(val => this.isLoggedIn = true)
    );*/

  }

  logout(): void {
    this.isLoggedIn = false;
  }

}
