import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private translate: TranslateService
  ) {
    this.initTranslate();
  }

  initTranslate() {
    // this.translate.setDefaultLang('zh-CN');
    const currentLanguage = window.localStorage.getItem('language');
    if (currentLanguage) {
      this.translate.use(currentLanguage);
    } else {
      this.translate.use('zh-CN');
      window.localStorage.setItem('language', 'zh-CN');
    }
  }
}
