import {Injectable} from '@angular/core';

@Injectable()
export class FunctionService {
  functionAuthValue = localStorage.getItem('functionList');
  functionAuth = this.functionAuthValue ? JSON.parse(this.functionAuthValue) : JSON.parse(localStorage.getItem('functionList'));
  currentUser: any;

  // currentUser:any;
  constructor() {
    console.log('FunctionService==========');
    this.currentUser = this.currentUser ? JSON.parse(this.currentUser) : JSON.parse(window.localStorage.getItem('currentUser'));
  }

  getCurrentFactoryId() {
    if (!this.currentUser) {
      this.currentUser = JSON.parse(window.localStorage.getItem('currentUser'));
    }
    console.log('this.currentUser.FactoryId=========', this.currentUser.FactoryId);
    return this.currentUser.FactoryId;
  }
}
