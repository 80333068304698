import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';

const routes: Routes = [
  {
    path: 'passport',
    loadChildren: './passport/passport.module#PassportModule',
  },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: './main/main.module#MainModule',
  },
  {
    path: '',
    redirectTo: 'passport',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
